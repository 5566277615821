* {
  margin: 0;
  padding: 0;
  user-select: none;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}
*::-webkit-scrollbar-thumb {
  background: #888;
}

.customInput {
  .ant-input-group-addon {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  .ant-input {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  border-radius: 6px;
}
.customSelect {
  .ant-select-selector {
    border-radius: 6px !important;
  }
}

.home {
  background: #f8f8f8;
  height: 100vh;
  overflow-x: hidden;
  &_body {
    display: flex;
    justify-content: space-between;
    position: sticky;
  }
}

.dropDown {
  pointer-events: none;
  width: 254px;
  position: fixed;
  z-index: 11;
  > div {
    transition: 0.3s;
    opacity: 0;
    background: #ffffff;
    padding: 24px;
    border-radius: 12px;
    > h1 {
      font-size: 20px;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ccd3da;
      color: #1d343a;
    }
    > div {
      width: 100%;
      > h1 {
        color: #1d343a;
        margin-bottom: 16px;
        font-size: 16px;
      }
      > div {
        > p {
          margin-bottom: 18px;
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
.dropDown.active {
  > div {
    opacity: 1;
  }
}
.preview-root {
  .ant-image-preview-operations {
    justify-content: center;
    .ant-image-preview-operations-operation:first-child {
      display: none;
    }
  }
}
