.filter {
  min-width: 308px;
  height: calc(100vh - 80px);
  padding: 16px;
  overflow-y: auto;
  &_container {
    background: #ffffff;
    box-shadow: 0px 4px 8px -2px rgba(27, 46, 94, 0.04);
    border-radius: 8px;
    width: 100%;
    height: auto;
    padding: 16px;
    &_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #757d8a;
      border-bottom: 1px solid #ebebeb;
      margin-bottom: 0;
      padding-bottom: 12px;
      > span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #ff3f40;
        cursor: pointer;
        user-select: none;
        &:active {
          > svg {
            transform: rotate(360deg);
          }
        }
        > svg {
          margin-right: 9px;
          transition: 0.3s;
        }
      }
    }
    &_flatsCount {
      border-bottom: 1px solid #ebebeb;
      > h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #757d8a;
        margin: 16px 0;
        text-align: center;
      }
      > div {
        display: grid;
        align-items: center;
        gap: 10px;
        margin-bottom: 16px;
        grid-template-columns: repeat(5, 1fr);
        > label {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 12px 19px;
          width: 40px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #e1e3e6;
          border-radius: 8px;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          color: #5a6474;
          transition: 0.3s;
        }
        > input {
          display: none;
          &:checked {
            + label {
              color: #ffffff;
              background: #00a389;
            }
          }
        }
      }
    }
    &_flatSlider {
      border-bottom: 1px solid #ebebeb;
      padding-bottom: 16px;
      > h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #757d8a;
        margin: 16px 0;
        text-align: center;
      }
      > p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #757d8a;
        padding: 8px 0 0;
        text-align: center;
      }
    }
    &_repaired {
      user-select: none;
      padding-bottom: 16px;
      border-bottom: 1px solid #ebebeb;
      > h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #757d8a;
        margin: 16px 0;
        text-align: center;
      }
      :global {
        margin: 0;
        .ant-radio-group {
          display: flex;
          flex-direction: column;
          gap: 4px 0;
          .ant-radio {
            top: auto;
          }
          .ant-radio-wrapper {
            background: #ffffff;
            border: 1px solid #e1e3e6;
            border-radius: 8px;
            padding: 11px 16px;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #404d61;
            display: flex;
            align-items: center;
            margin: 0;
          }
        }
      }
    }
    &_switch {
      display: flex;
      flex-direction: column;
      gap: 4px 0;
      padding-top: 16px;
      > label {
        user-select: none;
        display: flex;
        justify-content: space-between;
        background: #ffffff;
        border: 1px solid #e1e3e6;
        border-radius: 8px;
        padding: 12px 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #757d8a;
        cursor: pointer;
      }
    }
  }
}
