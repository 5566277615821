.visual {
  position: relative;
  width: 100%;
  height: 100%;
  &_center {
    height: 100%;
    overflow: auto;
    z-index: 0;
    position: relative;
    &_child{
      height: 100%;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
        box-shadow: inset 0px 0px 200px black;
      }
      > img {
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
      > svg {
        position: absolute;
        z-index: 1;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        > a {
          z-index: 10;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: hsl(0, 0%, 0%);
            z-index: 10;
          }
        }
        polygon {
          fill: transparent;
          transition: 0.3s;
          position: relative;
          animation-name: polygonAnim;
          animation-duration: 0.8s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-direction: alternate;
        }
        @keyframes polygonAnim {
          0% {
            fill: #ffffff50;
          }
          100% {
            fill: #ffffff00;
          }
        }
        path {
          pointer-events: none;
          fill: transparent;
          transition: 0.3s;
        }
        > a:hover {
          polygon {
            animation-play-state: paused;
            stroke: #d9d9d9;
            fill: #ffffff00;
          }
          path {
            fill: #00000090;
          }
        }
      }
    }
  }
  &_floor {
    height: 100%;
    overflow: auto;
    z-index: 0;
    position: relative;
    &_child{
      height: 100%;
      position: relative;  
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
        box-shadow: inset 0px 0px 200px black;
      }
      > img {
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
      > svg {
        position: absolute;
        z-index: 1;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        g {
          > path,
          > polygon {
            pointer-events: none;
          }
        }
        a {
          &:hover {
            > polygon {
              fill: #f9da8d50 !important;
            }
          }
        }
      }
    }
  }
  &_back {
    z-index: 10;
    position: absolute;
    left: 76px;
    top: 46px;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #ffffff;
    cursor: pointer;
    > svg {
      margin-right: 9px;
    }
  }
}


.blurImage {
  position: absolute;
  width: 100%;
  z-index: 0;
  filter: blur(6px);
}