.modal {
  :global {
    .ant-modal-content {
      border-radius: 12px;
      .ant-modal-body {
        padding: 47px 24px 24px;
        > form {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;
          > h1 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #000000;
            padding: 0 30px;
          }
          input {
            width: 100%;
            border-radius: 6px;
          }

          > button {
            width: 160px;
            height: 38px;
            background: #00a389;
            border-radius: 50px;
          }
        }
      }
    }
  }
}
.container{
  @media screen and (max-width: 768px) {
    opacity: 0;
  }
}
.active{
  @media screen and (max-width: 768px) {
    width: 100%;
    position: relative;
    z-index: 10;
    opacity: 1;
  }
}

.closeIcon{
  position: absolute;
  display: none;
  left: 4px;
  z-index: 2;
  font-size: 24px;
  font-weight: bold;
  top: 0px;
  justify-content: center;
  align-items: end;
  height: 32px;
  width: 32px;
  cursor: pointer;
  border-radius: 12px;
  background: #ffffff;
  line-height: 36px;
  border: 1px solid #cccccc;
  @media screen and (max-width: 768px) {
    display: flex;
  }
}
.rightDrawer {
  height: calc(100vh - 80px);
  overflow-y: auto;
  min-width: 0;
  overflow-x: hidden;
  position: relative;
  &_active {
    min-width: 328px;
   
  }
  &_open{
    @media screen and (max-width: 768px) {
      // position: absolute;
      left: 0;
      min-width: 100%;
      z-index: 1;
      top: 0;
      .rightDrawer_container{
        width: auto;
      }
    }
  }
  &_container {
    background: #ffffff;
    box-shadow: 0px 4px 8px -2px rgba(27, 46, 94, 0.04);
    border-radius: 8px;
    width: 268px;
    height: auto;
    padding: 16px;
    margin: 16px;
    &_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #ebebeb;
      > p {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #757d8a;
        margin-bottom: 0;
      }
      > span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #757d8a;
        display: flex;
        align-items: center;
        > svg {
          margin-right: 4px;
        }
      }
    }
    &_petitionButton {
      padding: 12px 0;
      border-bottom: 1px solid #ebebeb;
      margin-bottom: 12px;
      > button {
        background: linear-gradient(180deg, #08b395 0%, #0c977f 100%);
        border-radius: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
        height: 48px;
        width: 100%;
        &:hover,
        &:focus {
          background: linear-gradient(180deg, #08b395 0%, #0c977f 100%);
          color: #ffffff;
        }
      }
    }
    &_flatImage {
      border-bottom: 1px solid #ebebeb;
      display: flex;
      flex-direction: column;
      align-items: center;
      :global {
        margin: 0;
        .rightDrawer_container_flatImage_segmented {
          margin: 12px 0;
          border-radius: 8px;
          background: #e1e3e6;
          .ant-segmented-item-label {
            border-radius: 8px !important;
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #00a389;
            .ant-segmented-item-icon {
              display: inline-flex;
              align-items: center;
            }
          }
        }
      }
    }
    &_flatPrice {
      margin: 12px 0 0;
      border-bottom: 1px solid #ebebeb;
      > h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        color: #757d8a;
        margin-bottom: 12px;
        > span {
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
          background: #00a389;
          border-radius: 3px;
          padding: 3px 10px;
        }
      }
      > p {
        display: flex;
        justify-content: flex-end;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #757d8a;
        margin-bottom: 16px;
      }
    }
    &_flatInfo {
      > h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #757d8a;
        margin: 12px 0;
        text-align: center;
      }
      > div {
        > p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 32px;
          width: 100%;
          background: #f8f8f8;
          border-radius: 4px;
          padding: 0 5px;
          margin-bottom: 0;
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          color: #757d8a;
          &:nth-child(2n) {
            background: #ffffff;
          }
          > p {
            margin: 0;
          }
          > span {
            font-style: normal;
            font-weight: 700;
            font-size: 11px;
            line-height: 16px;
            text-align: right;
            color: #5a6474;
          }
        }
      }
      > p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #4e73f8;
        padding: 12px 0;
        cursor: pointer;
        user-select: none;
        > svg {
          width: 16px;
          margin-left: 7px;
        }
      }
    }
  }
}
