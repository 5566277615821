.header {
  background: #ffffff;
  border-bottom: 1px solid #ebebeb;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  &_left {
    display: flex;
    align-items: center;
    gap: 16px;
    &_logo {
      margin-left: 16px;
      border-right: 1px solid #ebebeb;
      padding-right: 16px;
    }
  }
  &_right {
    &_close {
      margin-right: 26px;
      cursor: pointer;
    }
  }
}
