$laptop: 1366px;

.bodyContainer {
  background: #ffffff;
  border-width: 0px 1px;
  border-style: solid;
  border-color: #ebebeb;
  width: calc(100% - 328px);
  transition: 0.3s;
  height: calc(100vh - 130px);
  position: fixed;
  left: 328px;
  top: 80px;
  z-index: 1;
  &_active {
    width: calc(100% - 328px - 328px);
    @media screen and (max-width: $laptop) {
      .bodyContainer_header_flatStatus {
        display: none;
      }
    }
  }
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    height: 50px;
    padding: 0 22px 0 16px;
    position: relative;
    :global {
      margin: 0;
      .BodyContainerArrowRight {
        background: linear-gradient(180deg, #08b395 0%, #0c977f 100%);
        border-radius: 8px;
        position: absolute;
        width: 23px;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        right: -11.5px;
        > svg {
          transition: 0.3s;
        }
      }
      .BodyContainerArrowRight.active {
        > svg {
          transform: rotate(-180deg);
        }
      }
      .bodyContainer_header_segmented {
        margin: 12px 0;
        border-radius: 8px;
        background: #f1f2f3;
        .ant-segmented-item-label {
          border-radius: 8px !important;
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #00a389;
          .ant-segmented-item-icon {
            display: inline-flex;
            align-items: center;
          }
        }
      }
    }
    &_flatCount {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #757d8a;
      min-width: 177px;
      > span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #5a6474;
        background: #f8f8f8;
        border-radius: 3px;
        padding: 4px 10px;
        margin-left: 8px;
      }
    }
    &_flatStatus {
      display: flex;
      gap: 0 16px;
      > p {
        margin-bottom: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #757d8a;
        display: flex;
        align-items: center;
        > svg {
          margin-right: 4px;
        }
      }
    }
  }
  &_body {
    display: flex;
    overflow: auto;
    min-width: 100%;
    max-height: calc(100vh - 50px - 130px);
    background: #ffffff;
    cursor: grab !important;
    position: relative;
    justify-content: space-between;
    gap: 0 44px;
    &:active {
      cursor: grabbing !important;
    }
    &_numberContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 52px;
      position: sticky;
      z-index: 2;
      left: 0;
      background: #ffffff;
      > span {
        min-height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #757d8a;
        background: #ffffff;
      }
    }
    &_entrance {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px 0;
      margin-right: auto;
      &_building {
        display: flex;
        gap: 0 16px;
      }
      > h1 {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #757d8a;
        min-height: 48px;
        display: flex;
        align-items: center;
        padding: 24px 0 0 0;
        margin-bottom: 0;
        position: sticky;
        top: 0;
        background: #fff;
        width: 100%;
        z-index: 3;
      }
      &_floor {
        display: flex;
        gap: 0 8px;
        margin-top: auto;
        height: 30px;
        &_entrance {
          display: flex;
          flex-direction: column;
          gap: 8px 4px;
        }
        &_apartament {
          width: 30px;
          min-height: 30px;
          background: #63cba5;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          user-select: none;
          position: relative;
          &_active {
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: calc(100% + 8px);
              height: calc(100% + 8px);
              border: 4px solid red;
              z-index: 1;
            }
          }
        }
      }
    }
  }
}
